exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-brief-jsx": () => import("./../../../src/pages/brief.jsx" /* webpackChunkName: "component---src-pages-brief-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kontakt-jsx": () => import("./../../../src/pages/kontakt.jsx" /* webpackChunkName: "component---src-pages-kontakt-jsx" */),
  "component---src-pages-o-mnie-jsx": () => import("./../../../src/pages/o-mnie.jsx" /* webpackChunkName: "component---src-pages-o-mnie-jsx" */),
  "component---src-pages-oferta-jsx": () => import("./../../../src/pages/oferta.jsx" /* webpackChunkName: "component---src-pages-oferta-jsx" */),
  "component---src-pages-polityka-prywatnosci-jsx": () => import("./../../../src/pages/polityka-prywatnosci.jsx" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-jsx" */),
  "component---src-pages-projekty-jsx": () => import("./../../../src/pages/projekty.jsx" /* webpackChunkName: "component---src-pages-projekty-jsx" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */)
}

